<template>
  <div class="flex justify-center gap-5">
    <img
      src="../../public/images/icons/united-kingdom.png"
      class="w-8 h-8"
      alt="English"
      v-on:click="$i18next.changeLanguage('en')"
    />
    <img
      src="../../public/images/icons/sweden.png"
      class="w-8 h-8"
      alt="Swedish"
      v-on:click="$i18next.changeLanguage('sv')"
    />
  </div>
</template>
<script setup lang="ts">

</script>
