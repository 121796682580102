import i18next from "i18next";
import I18NextVue from "i18next-vue";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "../public/locales/en/translation.json";
import translationSV from "../public/locales/sv/translation.json";

i18next.init({
  debug: true,
  fallbackLng: "sv",
  resources: {
    en: {
      translation: translationEN,
    },
    sv: {
      translation: translationSV,
    },
  },
});

export default function (app: any) {
  app.use(I18NextVue, { i18next });
  return app;
}
